
import {
  UpdateAvatarMutation,
  UpdateAvatarMutationMutation,
  ConsultantProfile,
  UploadFileInput,
} from "@/gql"
import { Component, Prop, Vue } from "vue-property-decorator"
import { Action } from "vuex-class"
import { Namespaces } from "@/constants"
import { UpdateSessionPayload } from "@/store/auth"
import Loader from "@/components/widgets/common/Loader.vue"

@Component({
  components: {
    Loader,
  },
})
export default class UpdateAvatar extends Vue {
  @Prop({ required: true }) readonly consultant!: ConsultantProfile
  @Prop() readonly hideDetails?: boolean
  @Prop() readonly refetchQueries!: any

  @Action("updateSession", { namespace: Namespaces.Auth })
  updateSession!: (payload: UpdateSessionPayload) => void

  isUploadingAvatar = false

  async updateUserAvatar(upload: UploadFileInput) {
    this.isUploadingAvatar = true

    try {
      const result = await this.$apollo.mutate<UpdateAvatarMutationMutation>({
        mutation: UpdateAvatarMutation,
        variables: { avatar: upload },
        refetchQueries: this.refetchQueries,
      })

      if (result.data && result.data.updateProfile) {
        const error = result.data.updateProfile.error

        if (error) {
          this.addMutationError(error)
        } else {
          this.$emit("save")
          this.addSuccess("Photo updated successfully")
          this.updateSession({ user: { avatar: result.data.updateProfile.profile?.avatar } })
        }
      }
    } catch (e: any) {
      this.addGraphQLError(e)
    } finally {
      this.isUploadingAvatar = false
    }
  }

  openFilePicker() {
    const fileNode = document.querySelector("#avatar-file-picker")
    fileNode && (fileNode as HTMLInputElement).click()
  }
}
