
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import CountrySelect from "@/components/widgets/input/CountrySelect.vue"
import { Getter } from "vuex-class"
import { Namespaces } from "@/constants"
import TwoStepSelect from "@/components/widgets/input/TwoStepSelect.vue"
import {
  Language,
  UploadFile,
  LanguagesSearchQuery,
  LanguagesSearchQueryQuery,
  ConsultantProfile,
  ConsultantNationality,
  ApplicantInput,
  UpdateConsultantProfileMutation,
  UpdateConsultantProfileMutationMutation,
  UpdateConsultantProfileMutationMutationVariables,
  ConsultantProfileInput,
  SaveApplicationMutationMutation,
  SaveApplicationMutationMutationVariables,
  SaveApplicationMutation,
  AddConsultantNationalityMutation,
  AddConsultantNationalityMutationMutation,
  DeleteConsultantNationalityMutationMutation,
  DeleteConsultantNationalityMutation,
  ConsultantApplication,
  AddConsultantSpokenLanguageMutationMutation,
  AddConsultantSpokenLanguageMutation,
  ConsultantSpokenLanguage,
  DeleteConsultantSpokenLanguageMutation,
  Mbti,
  UploadFileInput,
  ConsultantApplicationInput,
  JobTitle,
  UpdateConsultantJobTitlesMutation,
  UpdateConsultantJobTitlesMutationMutation,
  UpdateConsultantJobTitlesMutationMutationVariables,
  TalentPoolQuery,
} from "@/gql"
import { FetchResult } from "apollo-link"
import { proficiencies, EmploymentStatuses, JobFunctions } from "@/constants"
import PhoneInput from "@/components/widgets/input/PhoneInput.vue"
import JobRoleSelect from "@/components/widgets/input/JobRoleSelect.vue"
@Component({
  components: {
    CountrySelect,
    TwoStepSelect,
    PhoneInput,
    JobRoleSelect,
  },
})
export default class BasicInfoForm extends Vue {
  @Prop() readonly consultant!: ConsultantProfile
  @Prop() readonly refetchQueries!: any

  @Getter("getApplicant", { namespace: Namespaces.Apply })
  applicant!: ApplicantInput

  @Getter("hasApplication", { namespace: Namespaces.Apply })
  hasApplication?: boolean

  readonly LanguagesSearchQuery = LanguagesSearchQuery
  readonly proficiencies = proficiencies

  languages: string[] = []
  serverLanguages: Language[] = []
  cachedLanguages: ConsultantSpokenLanguage[] = []
  selectedLanguages: { primary: string; secondary: string }[] = []
  profilePresentation: UploadFile | UploadFileInput | null = null

  consultantNationalities: number[] = []
  cachedNationalities: ConsultantNationality[] = []
  employmentStatuses = EmploymentStatuses
  jobFunctions = JobFunctions

  form: ConsultantApplicationInput = {
    firstName: null,
    lastName: null,
    gender: null,
    dateOfBirth: null,
    currentOccupation: null,
    phone: null,
    residentialAddress: null,
    postalAddress: null,
    countryId: null,
    mbti: null,
    linkedinUrl: null,
    yearStartedWork: null,
    yearStartedFreelance: null,
    bio: null,
    africaNeeds: null,
    employmentStatus: null,
    jobFunctions: [],
    practiceArea: null,
    jobTitleId: null,
  }

  jobTitles: JobTitle[] = []

  jobTitleSaving = false
  jobTitleSaved = false

  get mbtiList() {
    return Object.values(Mbti)
  }

  get allConsultantNationalities() {
    return this.consultant.consultantNationalities
  }

  onLanguagesResult(result: FetchResult<LanguagesSearchQueryQuery>) {
    if (result.data) {
      this.serverLanguages = result.data.languages.data
      this.languages = result.data.languages.data.map((l) => l.name)
    }
  }

  @Watch("consultant", {
    immediate: true,
  })
  populateForm() {
    if (this.consultant) {
      // loop through keys of @form, if field is not null in @consultant assign to field in @form
      for (let field in this.form) {
        let current = this.consultant[field as keyof ConsultantProfile]

        if (current) {
          ;(this.form[field as keyof ConsultantProfileInput] as any) = current
        }
      }

      // Country of residence
      this.form.countryId = this.consultant.country ? this.consultant.country.id : null

      // Job Role
      this.form.jobTitleId = this.consultant.jobTitle ? this.consultant.jobTitle.id : null
      this.jobTitles = this.consultant.jobTitles ?? []

      this.cachedNationalities = [...this.consultant.consultantNationalities]
      this.cachedLanguages = [...this.consultant.consultantSpokenLanguages]

      // Populate nationalities
      this.consultantNationalities = (this.consultant.consultantNationalities || []).map(
        (nationality) => nationality.country.id
      )

      this.profilePresentation = this.consultant.profilePresentation || null

      // Populate selected languages
      this.selectedLanguages = this.cachedLanguages.map((sl) => {
        return {
          primary: sl.language.name,
          secondary: sl.fluency,
        }
      })
    }
  }

  async onConsultantNationalitiesChange() {
    if (this.consultantNationalities.length < this.cachedNationalities.length) {
      // nationality cleared
      await this.removeConsultantNationality()
      return
    }

    try {
      const result = await this.$apollo.mutate<AddConsultantNationalityMutationMutation>({
        mutation: AddConsultantNationalityMutation,
        variables: {
          applicant: this.applicant || undefined,
          countryId: this.consultantNationalities[this.consultantNationalities.length - 1],
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
        },
      })

      if (result && result.data && result.data.addConsultantNationality) {
        const error = result.data.addConsultantNationality.error

        if (error) {
          this.addMutationError(error)
          this.$emit("error", error)
        } else {
          if (result.data.addConsultantNationality.nationality) {
            this.cachedNationalities.push(result.data.addConsultantNationality.nationality)
          }
          this.$emit("save")
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    }
  }

  async removeConsultantNationality() {
    try {
      const result = await this.$apollo.mutate<DeleteConsultantNationalityMutationMutation>({
        mutation: DeleteConsultantNationalityMutation,
        variables: {
          applicant: this.applicant || undefined,
          nationalityId: this.cachedNationalities[this.cachedNationalities.length - 1].id,
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
        },
      })

      if (result && result.data && result.data.deleteConsultantNationality) {
        const error = result.data.deleteConsultantNationality.error

        if (error) {
          this.addMutationError(error)
          this.$emit("error", error)
        } else {
          this.cachedNationalities.pop()

          this.$emit("save")
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    }
  }

  async onSpokenLanguagesChange(value: TwoStepSelect[]) {
    const language = value[value.length - 1]

    if (this.selectedLanguages.length < this.cachedLanguages.length) {
      // spoken language cleared
      await this.removeSpokenLanguage()
      return
    }

    // Add spoken language
    try {
      const payload = {
        languageId: this.serverLanguages.find((l) => l.name == language.primary)!.id,
        fluency: language.secondary,
      }

      const result = await this.$apollo.mutate<AddConsultantSpokenLanguageMutationMutation>({
        mutation: AddConsultantSpokenLanguageMutation,
        variables: {
          applicant: this.applicant || undefined,
          consultantLanguage: { ...payload },
          consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
        },
      })

      if (result && result.data && result.data.addConsultantSpokenLanguage) {
        const error = result.data.addConsultantSpokenLanguage.error

        if (error) {
          this.addMutationError(error)
          this.$emit("error", error)
        } else {
          // save language in memory
          result.data.addConsultantSpokenLanguage.spokenLanguage &&
            this.cachedLanguages.push(result.data.addConsultantSpokenLanguage.spokenLanguage)
          this.$emit("save")
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
    }
  }

  async removeSpokenLanguage() {
    const result = await this.$apollo.mutate({
      mutation: DeleteConsultantSpokenLanguageMutation,
      variables: {
        applicant: this.applicant || undefined,
        languageId: this.cachedLanguages[this.cachedLanguages.length - 1].id,
        consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
      },
    })

    if (result && result.data && result.data.deleteConsultantSpokenLanguage) {
      const error = result.data.deleteConsultantSpokenLanguage.error

      if (error) {
        this.addMutationError(error)
        this.$emit("error", error)
      } else {
        // remove language from memory
        this.cachedLanguages.pop()
        this.$emit("save")
      }
    }
  }

  onChange(field: string) {
    let variables: { [key: string]: any } = {}
    variables[field] = this.form[field as keyof ConsultantProfileInput]
    // mutate
    this.save(variables)
  }

  async onChangeJobTitles() {
    this.jobTitleSaving = true

    if (this.applicant) return

    try {
      const result = await this.mutate<
        UpdateConsultantJobTitlesMutationMutation,
        UpdateConsultantJobTitlesMutationMutationVariables
      >({
        mutation: UpdateConsultantJobTitlesMutation,
        variables: {
          consultantId: this.consultant.prn,
          jobTitles: this.jobTitles,
        },
        refetchQueries: [
          {
            query: TalentPoolQuery,
            variables: {
              filter: {
                idIn: this.consultant.id,
              },
              per: 1,
            },
          },
        ],
      })

      this.jobTitleSaving = false
      if (result && result.data && result.data.updateConsultantJobTitles) {
        const error = result.data.updateConsultantJobTitles.error

        if (error) {
          this.addMutationError(error)
          this.$emit("error", error)
        } else {
          this.$emit("save")
          this.jobTitleSaved = true
          setTimeout(() => {
            this.jobTitleSaved = false
          }, 2000)
        }
      }
    } catch (e) {
      this.jobTitleSaving = false
      this.addGraphQLError(e as Error)
      this.$emit("error", e)
      throw e
    }
  }

  async save(vars?: { [key: string]: any }) {
    try {
      if (!this.applicant) {
        // Consultant
        const payload = vars || this.form

        const result = await this.$apollo.mutate<
          UpdateConsultantProfileMutationMutation,
          UpdateConsultantProfileMutationMutationVariables
        >({
          mutation: UpdateConsultantProfileMutation,
          variables: {
            profile: { ...payload },
            profilePresentation:
              this.profilePresentation &&
              !Object.prototype.hasOwnProperty.call(this.profilePresentation, "id")
                ? (this.profilePresentation as UploadFileInput)
                : undefined,
            consultantId: this.can("edit_talent") ? this.consultant.id : undefined,
          },
          refetchQueries: this.refetchQueries,
        })

        if (result && result.data && result.data.updateConsultantProfile) {
          const error = result.data.updateConsultantProfile.error

          if (error) {
            this.addMutationError(error)
            this.$emit("error", error)
            return false
          } else {
            this.$emit("save")
            return true
          }
        }
      } else {
        // Applicant
        const payload = vars || {
          ...this.form,
        }

        const result = await this.$apollo.mutate<
          SaveApplicationMutationMutation,
          SaveApplicationMutationMutationVariables
        >({
          mutation: SaveApplicationMutation,
          variables: {
            applicant: this.applicant,
            application: { ...payload },
          },
        })

        if (result && result.data && result.data.saveApplication) {
          const error = result.data.saveApplication.error

          if (error) {
            this.addMutationError(error)
            this.$emit("error", error)
          } else this.$emit("save", { ...result.data.saveApplication.application })
        }
      }
    } catch (e) {
      this.addGraphQLError(e as Error)
      this.$emit("error", e)
      throw e
    }
  }

  incompleteBasicInfo(application: ConsultantApplication) {
    let emptyField = ""
    const consultant = application || (this.consultant as ConsultantApplication)
    const requiredFields = [
      "firstName",
      "lastName",
      "gender",
      "dateOfBirth",
      "currentOccupation",
      "phone",
      "postalAddress",
      "countryId",
      "yearStartedWork",
      "yearStartedFreelance",
      "bio",
      "employmentStatus",
    ]
    for (let field in consultant) {
      if (requiredFields.includes(field)) {
        const value = consultant[field as keyof ConsultantApplication]

        if (!value || (Array.isArray(value) && value.length === 0) || value === "unknown") {
          emptyField = field
          break
        }
      }
    }

    return emptyField
  }
}
