
import { Vue, Component, Prop, Emit } from "vue-property-decorator"
import BasicInfoForm from "@/components/consultant/forms/BasicInfoForm.vue"
import { ConsultantProfile, ConsultantProfileQuery } from "@/gql"
import UpdateAvatar from "@/views/consultant/UpdateAvatar.vue"
import AddConsultantDocuments from "@/components/consultant/forms/AddConsultantDocuments.vue"

@Component({
  components: {
    BasicInfoForm,
    UpdateAvatar,
    AddConsultantDocuments,
  },
})
export default class EditProfile extends Vue {
  @Prop({ required: true }) readonly consultant!: ConsultantProfile

  saving = false
  refetchQueries = [
    {
      query: ConsultantProfileQuery,
    },
  ]

  async onSave() {
    this.saving = true
    // Save basic info via save method
    const saveBasicInfo = await (this.$refs.basicInfo as BasicInfoForm).save()

    if (saveBasicInfo) {
      this.onSaveComplete()
    }
  }

  @Emit("save")
  onSaveComplete() {
    this.saving = false
    this.addSuccess("Profile saved successfully")
  }
}
