
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator"
import {
  AddConsultantDocumentMutation,
  AddConsultantDocumentMutationMutation,
  ApplicantInput,
  ConsultantApplication,
  DeleteConsultantDocumentMutation,
  DeleteConsultantDocumentMutationMutation,
  UploadFile,
} from "@/gql"

@Component
export default class AddConsultantDocuments extends Vue {
  @Prop() readonly consultant!: ConsultantApplication
  @Prop() readonly applicant!: ApplicantInput
  @Prop() extras?: any

  form: { [key: string]: UploadFile | null | undefined } = {
    cover_letter: null,
    cv: null,
    transcript: null,
    certificate: null,
  }

  get isOwner() {
    return this.currentUser?.id === this.consultant?.user?.id
  }

  cachedDocuments: { [key: string]: UploadFile | null | undefined } = {}

  async onDocumentChange(uploadFile: UploadFile | null, type: string) {
    if (uploadFile === null) {
      // upload file deleted
      uploadFile = this.cachedDocuments[type]!
      await this.deleteConsultantDoc(uploadFile)
      return
    }
    const result = await this.$apollo.mutate<AddConsultantDocumentMutationMutation>({
      mutation: AddConsultantDocumentMutation,
      variables: {
        applicant: this.applicant,
        uploadFile: uploadFile,
      },
    })

    if (result && result.data && result.data.addConsultantDocument) {
      const error = result.data.addConsultantDocument.error

      if (error) {
        this.addMutationError(error)
        this.$emit("error", error)
      } else {
        // Set form to created uploadFile, this allows us delete immediately after upload because we now have the id
        this.form[type] = result.data.addConsultantDocument.consultantDocument!.uploadFile

        this.saveComplete()
      }
    }
  }

  async deleteConsultantDoc(uploadFile: UploadFile) {
    if (!uploadFile) return
    // delete
    const result = await this.$apollo.mutate<DeleteConsultantDocumentMutationMutation>({
      mutation: DeleteConsultantDocumentMutation,
      variables: {
        applicant: this.applicant,
        uploadFileId: uploadFile.id,
      },
    })

    if (result && result.data && result.data.deleteConsultantDocument) {
      const error = result.data.deleteConsultantDocument.error

      if (error) {
        this.addMutationError(error)
        this.$emit("error", error)
      } else {
        this.saveComplete()
      }
    }
  }

  saveComplete() {
    this.cachedDocuments = { ...this.form }

    // to be saved in store
    let consultant = { ...this.consultant }
    // update consultant documents
    consultant.consultantDocuments = (Object.values(this.cachedDocuments) as UploadFile[]).filter(
      (doc) => !!doc
    )

    this.$emit("save", { ...consultant })
  }

  @Emit("change")
  onChange(field: string) {
    let variables: { [key: string]: any } = {}
    variables[field] = this.extras[field]
    return variables
  }

  @Watch("consultant", { immediate: true })
  onConsultantChange() {
    // Set consultant documents
    let docs: UploadFile[] = []
    docs = (this.consultant.consultantDocuments?.map((doc) => doc) as UploadFile[]) || []
    if (docs.length) {
      for (let field in this.form) {
        this.form[field] = docs.find((doc) => doc.category == field)
      }
    }

    // save a cached copy of documents
    this.cachedDocuments = { ...this.form }
  }
}
